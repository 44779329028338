$c-support: $c-brag;

.lobby__support {
  a {
    @extend %flex-center-nowrap, %box-neat;

    background: $c-bg-box;
    font-size: 1.3em;
    padding: 0.2em 0.5em;

    @include transition(background);

    &:hover {
      background: $c-support;
    }

    &:hover .lobby__support__text,
    &:hover i::before {
      color: #fff;
    }
  }

  i {
    flex: 0 0 auto;
    margin: 0 0.5em;

    &::before {
      margin-#{$end-direction}: 0.15em;
      color: $c-support;
      font-size: 2.6em;

      @include transition(color);
    }
  }

  &__text {
    flex: 1 1 auto;
    color: $c-support;

    @include transition(color);

    strong {
      font-weight: normal;
      display: block;
    }

    span {
      @extend %nowrap-ellipsis;

      display: block;
      font-size: 0.8em;
    }
  }
}
